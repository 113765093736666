/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

const a = {
  colors: {
    background: `#FFFFFF`,
    footerBg: `transparent`
  }
}

export default a
